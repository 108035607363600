import { useMemo, useContext } from "react";
import Link from "next/link";
import CTA from "../CTA/cta.module";
import Context from "../context/context";
import styles from "./footer.module.scss";
import { DEFAULT_LOCATION, formatAddress, formatPhoneNumber } from "../../util/locations";

const Footer = (props) => {
  const { location } = useContext(Context);
  const locationToUse = useMemo(() => location ? location : DEFAULT_LOCATION, [location]);
  const formattedAddress = useMemo(() => formatAddress(locationToUse.address), [locationToUse])

  const columns = useMemo(
    () => ({
      left: [
        {
          heading: "Quick Links",
          links: [
            {
              title: "About Us",
              url: "/about-us",
            },
            {
              title: "How It Works",
              url: "/how-it-works",
            },
            {
              title: "Our Technology",
              url: "/about-us#our-technology",
            },
          ],
        },
        {
          heading: "Support",
          links: [
            {
              title: "Contact Us",
              url: "/contact-us",
            },
            {
              title: "FAQ",
              url: "/faq",
            },
            {
              title: "Business Opportunities",
              url: "/business-opportunities",
            },
          ],
        },
        {
          heading: "Social",
          links: [
            {
              title: "Instagram",
              url: locationToUse.socials.instagram,
            },
            {
              title: "Facebook",
              url: locationToUse.socials.facebook,
            },
          ],
        },
      ],
      right: [
        {
          heading: "Address",
          links: [
            {
              title: (
                <>
                  {formattedAddress.street}
                  <br />
                  {formattedAddress.city}, {formattedAddress.province}
                  <br />
                  {formattedAddress.postalCode}
                </>
              ),
              url: locationToUse.endpoints.GOOGLE_MAPS_LOC,
            },
          ],
        },
        {
          heading: "Contact Us",
          links: [
            {
              title: locationToUse.email,
              url: `mailto:${locationToUse.email}`,
            },
            {
              title: formatPhoneNumber(locationToUse.phone),
              url: `tel:+1${locationToUse.phone}`,
            },
          ],
        },
      ],
    }),
    [locationToUse, formattedAddress]
  );

  const renderInternalLink = (link, idx) => (
    <li key={idx}>
      <CTA
        href={link.url}
        text={link.title}
        target="_blank"
        className="text-body-small"
        analytics={props.analytics}
        src="Footer"
        dest={link.url}
      />
    </li>
  );

  const renderExternalLink = (link, idx) => (
    <li key={idx}>
      <Link href={link.url}>
        <a
          title={link.title}
          className="text-body-small"
          dangerouslySetInnerHTML={{ __html: link.title }}
        ></a>
      </Link>
    </li>
  );

  return (
    <footer className={`${styles["footer"]}`}>
      <div className="row section-content mb-5">
        {columns.left.map((column, index) => (
          <div className="col-12 col-sm-4 col-md-3 col-lg-2 pl-0" key={index}>
            <h6 className={`text-body-small bold ${styles["column-heading"]}`}>
              {column.heading}
            </h6>
            <ul className={styles["column-link-list"]}>
              {column.heading.toLowerCase() !== "social"
                ? column.links.map((link, idx) => renderExternalLink(link, idx))
                : column.links.map((link, idx) =>
                    renderInternalLink(link, idx)
                  )}
            </ul>
          </div>
        ))}
        {/* FILLER COLUMN / EMPTY SPACE */}
        <div className="d-none d-lg-inline col-lg-2"></div>
        {columns.right.map((column, index) => (
          <div className="col-12 col-sm-4 col-md-3 col-lg-2 pl-0" key={index}>
            <h6 className={`text-body-small bold ${styles["column-heading"]}`}>
              {column.heading}
            </h6>
            <ul className={styles["column-link-list"]}>
              {column.heading.toLowerCase() !== "address"
                ? column.links.map((link, idx) => renderExternalLink(link, idx))
                : column.links.map((link, idx) =>
                    renderInternalLink(link, idx)
                  )}
            </ul>
          </div>
        ))}
      </div>
      <div className="section-content">
        <p className={styles["copyright-notice"]}>
          Kudos Golf © {new Date().getFullYear()}
        </p>
        <div className={styles["bottom-links-container"]}>
          {[
            {
              href: "/privacy-policy",
              text: "Privacy Policy",
            },
            {
              href: "/terms-and-conditions",
              text: "Terms & Conditions",
            },
            {
              href: "/release-and-waiver",
              text: "Release & Waiver",
            },
          ].map((item, index) => (
            <a
              href={item.href}
              key={index}
              className={`text-body-small ${styles["bottom-link"]}`}
              title={item.text}
            >
              {item.text}
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
